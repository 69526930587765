define("discourse/plugins/discourse-code-review/discourse/routes/user-activity-approval-given", ["exports", "discourse/routes/user-topic-list", "discourse-i18n"], function (_exports, _userTopicList, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserActivityApprovalGiven extends _userTopicList.default {
    model() {
      const username = this.modelFor("user").username_lower;
      return this.store.findFiltered("topicList", {
        filter: `topics/approval-given/${username}`
      }).then(model => {
        // andrei: we agreed that this is an anti pattern,
        // it's better to avoid mutating a rest model like this
        // this place we'll be refactored later
        // see https://github.com/discourse/discourse/pull/14313#discussion_r708784704
        model.set("emptyState", this.emptyState());
        return model;
      });
    }
    emptyState() {
      const user = this.modelFor("user");
      let title;
      if (this.isCurrentUser(user)) {
        title = (0, _discourseI18n.i18n)("code_review.approval_given_empty_state_title");
      } else {
        title = (0, _discourseI18n.i18n)("code_review.approval_given_empty_state_title_others", {
          username: user.username
        });
      }
      const body = "";
      return {
        title,
        body
      };
    }
  }
  _exports.default = UserActivityApprovalGiven;
});